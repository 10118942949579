// src/components/NotFound.js
import React from 'react';

const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20%' ,marginBottom: '20%'}}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <br/>
      <a
        href="/"
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          textDecoration: 'none',
          cursor: 'pointer',
          color: 'white',
          borderRadius: '5px',
       

        }}
        className=' btn-primary'
      >
        Go Home
      </a>
      <br/>     <br/>     <br/>     <br/>     <br/>
    </div>
  );
};

export default NotFound;
