import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import { formatDistanceToNow } from 'date-fns'; // Import function for time ago formatting
import { enUS } from "date-fns/locale";

// Custom locale to remove "about" from the date string
const customLocale = {
  ...enUS,
  formatDistance: (token, count, options) => {
    const formattedString = enUS.formatDistance(token, count, options);
    return formattedString.replace(/^about\s/, ""); // Remove "about"
  },
};

const NewsItem = ({ item, layout }) => {
  const formatDate = (date) => {
    const parsedDate = new Date(date);
    return isNaN(parsedDate.getTime())
      ? "Date not available"
      : formatDistanceToNow(parsedDate, { addSuffix: true, locale: customLocale });
  };

  return layout === "first" ? (
    <div className="post--item post--layout-1">
      <div className="post--img">
        <Link to={`/news/${item.news_id}`} className="thumb">
          <img src={`https://dailynewscrypto.net${item.image}`} alt={item.title} />
        </Link>
        <Link to="#" className="cat">{item.category}</Link>
        <div className="post--info">
          <ul className="nav meta">
            <li><Link to="#">{item.author}</Link></li>
            <li><Link to="#">{formatDate(item.published_date)}</Link></li>
          </ul>
          <div className="title">
            <h3 className="h4 pbottom--30">
              <Link to={`/news/${item.news_id}`} className="btn-link">{item.title}</Link>
            </h3>
          </div>
        </div>
      </div>
      <li className="col-xs-12">
        <hr className="divider" />
      </li>
    </div>
  ) : (
    <div className="post--item post--layout-2">
      <div className="post--img">
        <Link to={`/news/${item.news_id}`} className="thumb">
          <img src={`https://dailynewscrypto.net${item.image}`} alt={item.title} />
        </Link>
        <div className="post--info">
          <ul className="nav meta">
            <li><Link to="#">{item.author}</Link></li>
            <li><Link to="#">{formatDate(new Date(item.published_date))} </Link></li>
          </ul>
          <div className="title">
            <h3 className="h4">
              <Link to={`/news/${item.news_id}`} className="btn-link">{item.title}</Link>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

const NewsList = () => {
  const [news, setNews] = useState([]);
  const [visibleCount, setVisibleCount] = useState(5);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get("https://dailynewscrypto.net/api.php?endpoint=news",{
          cache: 'no-store',  // Disable cache
        });
        setNews(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  const handleSeeMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };

  // Filter the news to exclude "Altcoin" category
  const filteredNews = news.filter((item) => item.category === "NFT & AI");

  return (
    <div>
   
      <div className="post--items post--items-2" data-ajax-content="outer">
        <div className="post--items-title" data-ajax="tab">
          <h2 className="h4">NFT & AI</h2>
          <div className="nav"></div>
        </div>
        <ul className="nav row gutter--15" data-ajax-content="inner">
          {filteredNews.slice(0, visibleCount).map((item, index) => (
            <React.Fragment key={item.news_id}>
              <li className={index === 0 ? "col-xs-12" : "col-xs-6"}>
                {index === 0 ? (
                  <NewsItem item={item} layout="first" />
                ) : (
                  <NewsItem item={item} layout="grid" />
                )}
              </li>
              {index !== 0 && (index + 1) % 2 === 1 && (
                <li className="col-xs-12">
                  <hr className="divider" />
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
        {visibleCount < filteredNews.length && (
          <div className="text-center">
            <button className="mt-4 btn btn-primary" onClick={handleSeeMore}>
              See More
            </button>
          </div>
        )}
        <div className="preloader bg--color-0--b" data-preloader={1}>
          <div className="preloader--inner"></div>
        </div>
      </div>
    </div>
  );
};

export default NewsList;
