import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { formatDistanceToNow } from "date-fns";

function LearningCenterDetail() {
  const { id } = useParams();
  const [resource, setResource] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchResource = async () => {
      try {
        const response = await fetch(`https://dailynewscrypto.net/api.php?endpoint=learning-center&id=${id}`);
        const data = await response.json();
        setResource(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching resource details:", error);
        setLoading(false);
      }
    };

    fetchResource();
  }, [id]);

  if (loading) {
    return (
      <section aria-busy="true">
        <p>Loading...</p>
      </section>
    );
  }

  if (!resource) {
    return (
      <section role="alert">
        <p>Resource not found.</p>
      </section>
    );
  }
// Handle missing image or invalid URL
const postImageUrl = "https://dailynewscrypto.net" + resource.image;
console.log("aaa",postImageUrl)

// Define character limits
const maxTitleLength = 60;


// Function to truncate text if it exceeds the limit
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};


 // Apply truncation based on the character limits
 const pageTitle = truncateText(resource.title, maxTitleLength);

 const stripTagsAndTruncate = (content) => {
  // Remove HTML tags using a regular expression
  const textOnly = content.replace(/<\/?[^>]+(>|$)/g, "");
  // Truncate to 50 characters
  return textOnly.slice(0, 80);
};

// Get truncated content without HTML tags
const truncatedContent = stripTagsAndTruncate(resource.description);

 console.log("aaa",truncatedContent)



 const resizeImageUrl = (url, maxWidth = 1200, maxHeight = 630) => {
  return `${url}?w=${maxWidth}&h=${maxHeight}&fit=crop&ar=1.91:1`;  // Adjust aspect ratio to 1.91:1
};

const imageUrl = resizeImageUrl(postImageUrl);

  return (
    <div>
        <Helmet>
       {/* Title and meta tags */}
            <title>{pageTitle}</title>
    
            {/* Open Graph (Facebook, LinkedIn, Pinterest) */}
            <meta property="og:title" content={pageTitle} />
            <meta property="og:description" content={truncatedContent} />
            <meta property="og:image" content={imageUrl} />
            <meta property="og:image:alt" content="Crypto Market Cap Overview" />
            <meta property="og:image:width" content="800" />
            <meta property="og:image:height" content="400" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://test.rootgroup.tech/" />
    
            {/* Twitter Cards (for X) */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={truncatedContent} />
            <meta name="twitter:image" content={imageUrl} />
            <meta name="twitter:image:alt" content="Crypto Market Cap Overview" />
            <meta name="twitter:creator" content="@yourtwitterhandle" />
            
            {/* Pinterest */}
            <meta property="og:image" content={imageUrl} />
            <meta name="pinterest:image" content={imageUrl} />
    
           </Helmet>
      <div className="wrapper">
        {/* Breadcrumb Section */}
        <div className="main--breadcrumb">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <a href="/" className="btn-link">
                  <i className="fa fm fa-home" />
                  Home
                </a>
              </li>
              <li>
                <a href="/LearningCenter" className="btn-link">
                  Learning Center
                </a>
              </li>
              <li className="active">
                <span>{resource.title}</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Content Section */}
        <div className="main-content--section pbottom--30">
          <div className="container">
            <div className="row">
              <div className="main--content col-md-8" data-sticky-content="true">
                <div className="sticky-content-inner">
                  <div className="post--item post--single post--title-largest pd--30-0">
                    <div className="post--img">
                      <a href="#" className="thumb">
                        <img src={imageUrl} alt={resource.title} />
                      </a>
                    </div>

                    {/* Post Categories */}
                    <div className="post--cats">
                      <ul className="nav">
                        <li><span><i className="fa fa-folder-open-o" /></span></li>
                        <li><a href="#">{resource.title}</a></li>
                      </ul>
                    </div>

                    {/* Post Info */}
                    <div className="post--info">
                      <ul className="nav meta">
                        <li><a href="#"><i className="bi bi-clock" style={{ marginRight: '8px' }} />{formatDistanceToNow(new Date(resource.created_at))} ago</a></li>
                      </ul>
                      <div className="title">
                        <h2 className="h4">{resource.title}</h2>
                      </div>
                    </div>

                    {/* Post Content */}
                    <div className="post--content">
                      <div dangerouslySetInnerHTML={{ __html: resource.description }} />
                    </div>
                  </div>

                  {/* Post Social */}
                  <div className="post--social pbottom--30">
                    <span className="title">
                      <i className="fa fa-share-alt" />
                    </span>
                    <div className="social--widget style--4">
                      <ul className="nav">
                        <li>
                          <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=https://dailynewscrypto.net/LearningCenterDetail/${resource.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://x.com/intent/tweet?url=https://dailynewscrypto.net/LearningCenterDetail/${resource.id}&text=${encodeURIComponent(resource.title)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://www.linkedin.com/shareArticle?url=https://dailynewscrypto.net/LearningCenterDetail/${resource.id}&title=${encodeURIComponent(resource.title)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>

              {/* Sidebar */}
              <div className="main--sidebar col-md-4 ptop--30 pbottom--30" data-sticky-content="true">
                <div className="sticky-content-inner">
                  {/* Newsletter Widget */}
                  <div className="widget">
                    <div className="widget--title">
                      <h2 className="h4">Get Newsletter</h2>
                      <i className="icon fa fa-envelope-open-o" />
                    </div>
                    <div className="subscribe--widget">
                      <form action="" method="post" target="_blank">
                        <div className="input-group">
                          <input type="email" name="EMAIL" placeholder="E-mail address" className="form-control" required />
                          <div className="input-group-btn">
                            <button type="submit" className="btn btn-lg btn-default active">
                              <i className="fa fa-paper-plane-o" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className="widget hidden-xss ">
                    <div className="widget--title">
                      <h2 className="h4">Advertisement</h2>
                      <i className="icon fa fa-bullhorn" />
                    </div>
                    <div className="ad--widget">
                      <a href="#">
                        <img src="/img/ads-img/ad-300x250-2.jpg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LearningCenterDetail;
