import React, { useState, useEffect } from 'react';

const Header = () => {
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);

    const handleSearchClick = () => {
        setIsSearchActive(true);
    };

    const handleSearchOutsideClick = (e) => {
        if (!e.target.closest('.header--search-form')) {
            setIsSearchActive(false);
        }
    };

    const handleNavbarToggleClick = () => {
        setIsNavbarOpen((prevState) => !prevState);
    };

    const handleNavbarOutsideClick = (e) => {
        if (!e.target.closest('#headerNav')) {
            setIsNavbarOpen(false);
        }
    };

    useEffect(() => {
        if (isSearchActive) {
            const handleClickOutside = (e) => {
                handleSearchOutsideClick(e);
            };
            document.addEventListener('click', handleClickOutside);

            return () => {
                document.removeEventListener('click', handleClickOutside);
            };
        }
    }, [isSearchActive]);

    useEffect(() => {
        if (isNavbarOpen) {
            const handleNavbarClickOutside = (e) => {
                handleNavbarOutsideClick(e);
            };
            document.addEventListener('click', handleNavbarClickOutside);

            return () => {
                document.removeEventListener('click', handleNavbarClickOutside);
            };
        }
    }, [isNavbarOpen]);

    return (
        <header>
            <div className={`header--search-form ${isSearchActive ? 'active' : ''}`} onClick={handleSearchClick}>
                <button className="btn">Search</button>
                <input className="form-control" type="text" placeholder="Search..." />
            </div>

            <nav className="header--navbar">
                <button className={`navbar-toggle ${isNavbarOpen ? '' : 'collapsed'}`} onClick={handleNavbarToggleClick}>
                    Toggle Navbar
                </button>
                <div id="headerNav" className={`navbar-collapse ${isNavbarOpen ? 'in' : ''}`}>
                    {/* Navbar content */}
                </div>
            </nav>
        </header>
    );
};

export default Header;
