import React from "react";
import { Helmet } from "react-helmet";
import TheLatestCryptoNews from "./subnews/The Latest Crypto News";
import Bitcoin from "./subnews/Bitcoin";
import Finance from "./subnews/Finance";
import Economic from "./subnews/Economic";
import Altcoin from "./subnews/Altcoin";
import NFTAI from "./subnews/NFT & AI";
import DeFi from "./subnews/DeFi";

function App() {
  return (
    <div>
   
      <div className="row">
        {/* Main Content Start */}
        <div className="main--content col-md-8 col-sm-7" data-sticky-content="true">
          <div className="sticky-content-inner">
            <div className="row">
              {/* The Latest Crypto News */}
              <div className="col-md-6 ptop--30 pbottom--30">
                <TheLatestCryptoNews />
              </div>
              {/* Bitcoin */}
              <div className="col-md-6 ptop--30 pbottom--30">
                <Bitcoin />
              </div>
              {/* Advertisement */}
              {/* <div className="col-md-12 ptop--30 pbottom--30">
                <div className="ad--space">
                  <a href="#">
                    <img
                      src="/img/ads-img/ad-728x90-01.jpg"
                      alt="Crypto Advertisement"
                      className="center-block"
                    />
                  </a>
                </div>
              </div> */}
              {/* Finance */}
              <Finance />
              {/* <div className="col-md-12 ptop--30 pbottom--30">
                <div className="ad--space">
                  <a href="#">
                    <img
                      src="img/ads-img/ad-728x90-01.jpg"
                      alt="Crypto Ad Banner"
                      className="center-block"
                    />
                  </a>
                </div>
              </div> */}
              {/* Economic */}
              <div className="col-md-6 ptop--30 pbottom--30">
                <Economic />
              </div>
              {/* Altcoin */}
              <div className="col-md-6 ptop--30 pbottom--30">
                <Altcoin />
              </div>
              {/* Advertisement */}
              {/* <div className="col-md-12 ptop--30 pbottom--30">
                <div className="ad--space">
                  <a href="#">
                    <img
                      src="/img/ads-img/ad-728x90-01.jpg"
                      alt="Altcoin Ad Banner"
                      className="center-block"
                    />
                  </a>
                </div>
              </div> */}
              {/* NFT & AI */}
              <div className="col-md-6 ptop--30 pbottom--30">
                <NFTAI />
              </div>
              {/* DeFi */}
              <div className="col-md-6 ptop--30 pbottom--30">
                <DeFi />
              </div>
            </div>
          </div>
        </div>
        {/* Main Content End */}
        {/* Sidebar */}
        <div className="main--sidebar col-md-4 col-sm-5 ptop--30 pbottom--30" data-sticky-content="true">
          <div className="sticky-content-inner">
            {/* <div className="widget">
              <div className="ad--widget">
                <a href="#">
                  <img
                    src="/img/ads-img/ad-300x250-1.jpg"
                    alt="Sidebar Advertisement"
                  />
                </a>
              </div>
            </div> */}
            {/* <div className="widget">
              <div className="widget--title">
                <h2 className="h4">Stay Connected</h2>
              </div>
              <div className="social--widget style--1">
                <ul className="nav">
                  <li className="facebook">
                    <a href="#">
                      <span className="icon"><i className="fa fa-facebook-f" /></span>
                      <span className="count">521</span>
                      <span className="title">Likes</span>
                    </a>
                  </li>
                  <li className="twitter">
                    <a href="#">
                      <span className="icon"><i className="fa fa-twitter" /></span>
                      <span className="count">3297</span>
                      <span className="title">Followers</span>
                    </a>
                  </li>
                  <li className="youtube">
                    <a href="#">
                      <span className="icon"><i className="fa fa-youtube-square" /></span>
                      <span className="count">596282</span>
                      <span className="title">Subscribers</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="widget">
              <div className="widget--title">
                <h2 className="h4">Get Newsletter</h2>
              </div>
              <div className="subscribe--widget">
                <p>Subscribe to our newsletter for the latest crypto news and updates.</p>
                <form action="" method="post">
                  <div className="input-group">
                    <input
                      type="email"
                      name="EMAIL"
                      placeholder="E-mail address"
                      className="form-control"
                      autoComplete="off"
                      required
                    />
                    <div className="input-group-btn">
                      <button type="submit" className="btn btn-lg btn-default active">
                        <i className="fa fa-paper-plane-o" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* Sidebar End */}
      </div>
    </div>
  );
}

export default App;
