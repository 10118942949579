import React from 'react';
import { ReactComponent as Logo } from '../logo.svg'; // Adjust path as needed
import { Helmet } from 'react-helmet';

const AboutPage = () => {
  return (
    <div className="wrapper">
      {/* SEO Meta Tags */}
      <Helmet>
        <title>About Us | dailynewscrypto.net - Your Source for Crypto News</title>
        <meta
          name="description"
          content="Learn more about dailynewscrypto.net, your trusted source for real-time cryptocurrency news, market analysis, and in-depth articles. Stay informed with our mission-driven content."
        />
        <meta
          name="keywords"
          content="about dailynewscrypto, cryptocurrency news, blockchain updates, crypto market analysis, digital assets"
        />
        <meta name="author" content="dailynewscrypto.net Team" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="About Us | dailynewscrypto.net - Your Source for Crypto News" />
        <meta
          property="og:description"
          content="Discover the vision, mission, and goals of dailynewscrypto.net. We provide accurate, real-time updates on cryptocurrency trends, innovations, and market insights."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://dailynewscrypto.net/about" />
        <meta property="og:image" content="https://dailynewscrypto.net/img/og-about-page.jpg" />
        <link rel="canonical" href="https://dailynewscrypto.net/about" />
      </Helmet>

      {/* Main Breadcrumb Start */}
      <div className="main--breadcrumb">
        <div className="container">
          <ul className="breadcrumb">
            <li>
              <a href="/" className="btn-link">
                <i className="fa fm fa-home"></i>Home
              </a>
            </li>
            <li className="active">
              <span>About</span>
            </li>
          </ul>
        </div>
      </div>
      {/* Main Breadcrumb End */}

      {/* Main Content Section Start */}
      <div className="main-content--section pbottom--30">
        <div className="container">
          {/* Main Content Start */}
          <div className="main--content">
            {/* Post Item Start */}
            <div className="post--item post--single pd--30-0">
              <div className="row">
                <div className="col-md-6">
                  <div className="post--image">
                    <Logo className="img-fluid" /> {/* Use the imported SVG as an image */}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="post--info">
                    <div className="title">
                      <h2 className="h4">About dailynewscrypto.net</h2>
                    </div>
                  </div>

                  <div className="post--content">
                    <p>
                      dailynewscrypto.net is your go-to source for the latest news and trends in the world of cryptocurrency.
                      We provide real-time updates, expert analysis, and in-depth articles on all things crypto.
                    </p>

                    <blockquote>
                      <p>
                        "The cryptocurrency market is constantly evolving, and we strive to bring you the most accurate and
                        timely information to help you stay informed and make educated decisions."
                      </p>
                      <footer>dailynewscrypto.net Team</footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
            {/* Post Item End */}

            {/* Info Blocks Start */}
            <div className="info--blocks ptop--30">
              <ul className="nav row">
                <li className="col-md-3 col-xs-6 col-xxs-12 pbottom--30">
                  {/* Info Block Start */}
                  <div className="info--block">
                    <div className="icon text--color-1">
                      <i className="fa fa-dashboard"></i>
                    </div>

                    <div className="title">
                      <h3 className="h5">Our Goal</h3>
                    </div>

                    <div className="content">
                      <p>
                        Our goal at dailynewscrypto.net is to provide accurate, real-time information to help cryptocurrency
                        enthusiasts stay up-to-date with the latest trends, innovations, and developments in the digital asset space.
                      </p>
                    </div>
                  </div>
                  {/* Info Block End */}
                </li>

                <li className="col-md-3 col-xs-6 col-xxs-12 pbottom--30">
                  {/* Info Block Start */}
                  <div className="info--block">
                    <div className="icon text--color-1">
                      <i className="fa fa-cog"></i>
                    </div>

                    <div className="title">
                      <h3 className="h5">Our Vision</h3>
                    </div>

                    <div className="content">
                      <p>
                        Our vision is to be a trusted source of information and analysis for anyone looking to navigate the
                        ever-changing world of cryptocurrency, offering insights that empower users to make informed decisions.
                      </p>
                    </div>
                  </div>
                  {/* Info Block End */}
                </li>

                <li className="col-md-3 col-xs-6 col-xxs-12 pbottom--30">
                  {/* Info Block Start */}
                  <div className="info--block">
                    <div className="icon text--color-1">
                      <i className="fa fa-diamond"></i>
                    </div>

                    <div className="title">
                      <h3 className="h5">Our Mission</h3>
                    </div>

                    <div className="content">
                      <p>
                        Our mission is to provide in-depth and reliable news on cryptocurrency, educate users, and contribute
                        to the growth of the blockchain industry by offering transparency and knowledge.
                      </p>
                    </div>
                  </div>
                  {/* Info Block End */}
                </li>

                <li className="col-md-3 col-xs-6 col-xxs-12 pbottom--30">
                  {/* Info Block Start */}
                  <div className="info--block">
                    <div className="icon text--color-1">
                      <i className="fa fa-object-group"></i>
                    </div>

                    <div className="title">
                      <h3 className="h5">Our Objectives</h3>
                    </div>

                    <div className="content">
                      <p>
                        We aim to offer a comprehensive platform for the cryptocurrency community, with news, guides, reviews,
                        and tutorials that foster greater understanding and participation in the crypto ecosystem.
                      </p>
                    </div>
                  </div>
                  {/* Info Block End */}
                </li>
              </ul>
            </div>
            {/* Info Blocks End */}
          </div>
          {/* Main Content End */}
        </div>
      </div>
      {/* Footer Section End */}
    </div>
  );
};

export default AboutPage;
