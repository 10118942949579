import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDistanceToNow } from 'date-fns'; // Import function for time ago formatting
import { enUS } from "date-fns/locale";
  
// Custom locale to remove "about" from the date string
const customLocale = {
  ...enUS,
  formatDistance: (token, count, options) => {
    const formattedString = enUS.formatDistance(token, count, options);
    return formattedString.replace(/^about\s/, ""); // Remove "about"
  },
};
const FinanceNews = () => {
  const [posts, setPosts] = useState([]);
  const [displayCount, setDisplayCount] = useState(5); // Number of posts to display initially

 
  

    const formatDate = (date) => {
      const parsedDate = new Date(date);
      return isNaN(parsedDate.getTime())
        ? "Date not available"
        : formatDistanceToNow(parsedDate, { addSuffix: true, locale: customLocale });
    };
  
  useEffect(() => {
    // Fetch all posts from the API
    fetch('https://dailynewscrypto.net/api.php?endpoint=news')
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // Filter posts where category is 'Finance'
        const filteredPosts = data.filter(post => post.category === 'Finance',{
          cache: 'no-store',  // Disable cache
        });
        setPosts(filteredPosts);
      })
      .catch((error) => console.error('Error fetching posts:', error));
  }, []);

  const handleSeeMoreClick = () => {
    setDisplayCount((prevCount) => prevCount + 3); // Show 3 more posts on each click
  };


  return (
    <>
 
      <div className="col-md-12 ptop--30 pbottom--30">
        {/* Post Items Title Start */}
        <div className="post--items-title" data-ajax="tab">
          <h2 className="h4">Finance</h2>
        </div>
        {/* Post Items Title End */}

        {/* Post Items Start */}
        <div className="post--items post--items-2" data-ajax-content="outer">
          <ul className="nav row" data-ajax-content="inner">
            {posts.length > 0 ? (
              posts.slice(0, 1).map((post) => ( // Display only the first post
                <li key={post.news_id} className="col-md-6">
                  {/* Post Item Start */}
                  <div className="post--item post--layout-2">
                    <div className="post--img">
                      <Link to={`/news/${post.news_id}`} className="thumb">
                        <img src={`https://dailynewscrypto.net${post.image}`} alt={post.title} />
                      </Link>
                      <Link to={`/category/${post.category}`} className="cat">{post.category}</Link>
                      <div className="post--info">
                        <ul className="nav meta">
                          <li><Link to={`/author/${post.author}`}>{post.author}</Link></li>
                          <li><span>{formatDate(post.published_date)}</span></li>
                        </ul>
                        <div className="title">
                          <h3 className="h4">
                            <Link to={`/news/${post.news_id}`} className="btn-link">
                              {post.title}
                            </Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Post Item End */}
                </li>
              ))
            ) : (
              <p>No Finance-related news available.</p>
            )}

            <li className="col-xs-12 hidden-lg">
              {/* Divider Start */}
              <hr className="divider" />
              {/* Divider End */}
            </li>

            <li className="col-md-6">
              <ul className="nav row">
                {posts.length > 0 ? (
                  posts.slice(1, displayCount).map((post) => ( // Display posts from index 1 to displayCount
                    <li key={post.news_id} className="col-xs-6">
                      {/* Post Item Start */}
                      <div className="post--item post--layout-2">
                        <div className="post--img">
                          <Link to={`/news/${post.news_id}`} className="thumb">
                            <img src={`https://dailynewscrypto.net${post.image}`} alt={post.title} />
                          </Link>
                          <div className="post--info">
                            <ul className="nav meta">
                              <li><Link to={`/author/${post.author}`}>{post.author}</Link></li>
                              <li><span>{formatDate(post.published_date)}</span></li>
                            </ul>
                            <div className="title">
                              <h3 className="h4 pbottom--30">
                                <Link to={`/news/${post.news_id}`} className="btn-link">
                                  {post.title}
                                </Link>
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Post Item End */}
                    </li>
                  ))
                ) : (
                  <p>No Finance-related news available.</p>
                )}
              </ul>
            </li>

          </ul>
          <div className="text-center">
            {posts.length > displayCount && (
              <button className="mt-4 btn btn-primary" onClick={handleSeeMoreClick}>
                See More
              </button>
            )}
          </div>

          {/* Preloader Start */}
          <div className="preloader bg--color-0--b" data-preloader={1}>
            <div className="preloader--inner" />
          </div>
          {/* Preloader End */}
        </div>
        {/* Post Items End */}
      </div>
    </>
  );
};

export default FinanceNews;
