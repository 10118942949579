import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import './CryptoTicker.css';

const CryptoTicker = () => {
  const [lastUpdated, setLastUpdated] = useState('5 minutes ago');
  const [topCoins, setTopCoins] = useState([]);  // Initialize as an empty array

  // Fetch top 10 cryptocurrency data from the API
  useEffect(() => {
    const fetchCryptoData = async () => {
      try {
        // Using CoinGecko API to get the top 10 cryptocurrencies (free API, no key needed)
        const response = await fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=10&page=1');
        const data = await response.json();
        
        // Ensure that data is an array before setting the state
        if (Array.isArray(data)) {
          setTopCoins(data);  // Update state with fetched coin data
        } else {
          console.error('Expected an array but got:', data);
        }
      } catch (error) {
        console.error('Error fetching crypto data:', error);
      }
    };

    fetchCryptoData();

    // Update the "lastUpdated" value periodically if needed
    const timer = setInterval(() => {
      setLastUpdated('12 minutes ago'); // Replace with logic to compute time dynamically
    }, 60000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  // Function to determine the color based on price change
  const getPriceChangeColor = (change) => {
    return change > 0 ? 'green' : 'red';
  };

  return (
    <div className="crypto--ticker">
      <Helmet>
        <title>Top 10 Cryptocurrencies - Live Price Updates</title>
        <meta name="description" content="Stay updated with the latest prices of the top 10 cryptocurrencies. Check live price changes, market caps, and more." />
        <meta name="keywords" content="cryptocurrency, bitcoin, ethereum, crypto price, top coins, crypto market" />
        <meta property="og:title" content="Top 10 Cryptocurrencies - Live Price Updates" />
        <meta property="og:description" content="Stay updated with the latest prices of the top 10 cryptocurrencies. Check live price changes, market caps, and more." />
        <meta property="og:image" content="path_to_image.jpg" />
        <meta property="og:url" content="https://yourwebsite.com/crypto-prices" />
        <meta name="twitter:title" content="Top 10 Cryptocurrencies - Live Price Updates" />
        <meta name="twitter:description" content="Stay updated with the latest prices of the top 10 cryptocurrencies. Check live price changes, market caps, and more." />
        <meta name="twitter:image" content="path_to_image.jpg" />
      </Helmet>
      <div className="container">
        <br/>
        <br/>
        <div className="crypto-updates--list d-flex" data-marquee="true" id='dFlex'>
          <ul className="nav" id='price-crypto-updates'>
            {topCoins.length > 0 ? (
              topCoins.map((coin, index) => (
                <li key={index} className="crypto-item">
                  <div className="coin-info">
                    <img 
                      src={coin.image} 
                      alt={coin.name} 
                      className="coin-image"
                    />
                    <p>{coin.symbol.toUpperCase()}</p>
                    <p className="coin-name d-flex">
                      <a  target="_blank" rel="noopener noreferrer">
                        ${coin.current_price.toFixed(2)}
                        <span 
                          style={{
                            color: getPriceChangeColor(coin.price_change_percentage_24h),
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {coin.price_change_percentage_24h > 0 ? (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" className="bi bi-arrow-up" viewBox="0 0 16 16">
                              <path d="M8 12V3.5l4 4.5H4l4-4.5V12z"/>
                            </svg>
                          ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="red" className="bi bi-arrow-down" viewBox="0 0 16 16">
                              <path d="M8 4v8.5l-4-4.5h8l-4 4.5V4z"/>
                            </svg>
                          )}
                          {coin.price_change_percentage_24h?.toFixed(2)}%
                        </span>
                      </a>
                    </p>
                  </div>
                </li>
              ))
            ) : (
              <li>Loading top coins...</li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CryptoTicker;
