import React from "react";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div>
     
      <div className="ad--space pd--30-0">
        <a href="#">
          <img src="/img/ads-img/ad-970x90.jpg" alt="Ad banner" className="center-block" />
        </a>
      </div>
    </div>
  );
}

export default App;
