

  // utils/encodeImageURL.js

export const encodeURL = (url) => {
    // Split the URL into the base and query parts
    const [baseUrl, queryParams] = url.split('?');
  
    // Encode the base URL only for file names
    const encodedBaseUrl = baseUrl.replace(/ /g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29');
  
    // If there's a query part, encode the query parameters as well
    const encodedQueryParams = queryParams ? queryParams.replace(/ /g, '%20').replace(/\(/g, '%28').replace(/\)/g, '%29') : '';
  
    // Combine the encoded base URL and query parameters back together
    const finalUrl = `${encodedBaseUrl}${encodedQueryParams ? '?' + encodedQueryParams : ''}`;
    
    return finalUrl;
  };
  