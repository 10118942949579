import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { formatDistanceToNow } from 'date-fns';
import { enUS } from "date-fns/locale";
import Seo from '../seo';
  // Custom locale to remove "about" from the date string
  const customLocale = {
    ...enUS,
    formatDistance: (token, count, options) => {
      const formattedString = enUS.formatDistance(token, count, options);
      return formattedString.replace(/^about\s/, ""); // Remove "about"
    },
  };
function NewsDetails() {


   const formatDate = (date) => {
       const parsedDate = new Date(date);
       return isNaN(parsedDate.getTime())
         ? "Date not available"
         : formatDistanceToNow(parsedDate, { addSuffix: true, locale: customLocale });
     };
  const { id } = useParams();
  const [posts, setPosts] = useState([]);
  const [currentPost, setCurrentPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPostsData = async () => {
      try {
        const response = await fetch('https://dailynewscrypto.net/api.php?endpoint=news',{
          cache: 'no-store',  // Disable cache
        });
        const data = await response.json();

        const filteredPosts = data.filter(post => parseInt(post.news_id, 10) === parseInt(id, 10));
        setPosts(filteredPosts);
        setCurrentPost(filteredPosts[0]);

        if (filteredPosts[0]?.category) {
          const categoryname = filteredPosts[0].category;
          const relatedPostsData = data.filter(post => post.category === categoryname && post.news_id !== filteredPosts[0].news_id);
          setRelatedPosts(relatedPostsData);
        }

        setLoading(false);
      } catch (err) {
        setError('Error fetching posts');
        setLoading(false);
      }
    };

    fetchPostsData();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!currentPost) {
    return <div>No news found</div>;
  }

  // Handle missing image or invalid URL
  const postImageUrl = "https://dailynewscrypto.net" + currentPost.image;
 console.log("aaa",postImageUrl)

 // Define character limits
 const maxTitleLength = 60;
 const maxDescriptionLength = 150;
 
 // Function to truncate text if it exceeds the limit
 const truncateText = (text, maxLength) => {
   if (text.length > maxLength) {
     return text.slice(0, maxLength) + "...";
   }
   return text;
 };

 // Apply truncation based on the character limits
 const pageTitle = truncateText(currentPost.title, maxTitleLength);
 const pageDescription = truncateText(currentPost.title, maxDescriptionLength);
 const maxImageWidth = 1200; // Maximum allowed width for image
 const maxImageHeight = 400; // Maximum allowed height for image
 const urll = `https://dailynewscrypto.net/news/${id}`;
 const stripTagsAndTruncate = (content) => {
  // Remove HTML tags using a regular expression
  const textOnly = content.replace(/<\/?[^>]+(>|$)/g, "");
  // Truncate to 50 characters
  return textOnly.slice(0, 80);
};

// Get truncated content without HTML tags
const truncatedContent = stripTagsAndTruncate(currentPost.content);

 console.log("aaa",truncatedContent)



 const resizeImageUrl = (url, maxWidth = 1200, maxHeight = 630) => {
  return `${url}?w=${maxWidth}&h=${maxHeight}&fit=crop&ar=1.91:1`;  // Adjust aspect ratio to 1.91:1
};
 const imageUrl = resizeImageUrl(postImageUrl);

  return (
    <div>
<Seo
 title={currentPost.title} 
 description={currentPost.content} 
 imageUrl={imageUrl}
 url={urll}

/>



      <div className="wrapper">
        <div className="main--breadcrumb">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <a href="/" className="btn-link">
                  <i className="fa fm fa-home" /> Home
                </a>
              </li>
              {currentPost.categories?.length > 0 && (
                <li>
                  <a href={`/${currentPost.categories[0].toLowerCase()}`} className="btn-link">
                    {currentPost.categories[0]}
                  </a>
                </li>
              )}
              <li className="active">
                <span>{currentPost.title}</span>
              </li>
            </ul>
          </div>
        </div>

        <div className="main-content--section pbottom--30">
          <div className="container">
            <div className="row">
              <div className="main--content col-md-8" data-sticky-content="true">
                <div className="sticky-content-inner">
                  <div className="post--item post--single post--title-largest pd--30-0">
                    <div className="post--img">
                      <a href="#" className="thumb">
                        <img src={postImageUrl} alt={currentPost.title} />
                      </a>
                    </div>
                    <div className="post--content">
                      <div className="post--info">
                        <ul className="nav meta">
                          <li><a href="#"> <i className="bi bi-person" style={{ marginRight: '8px' }} /> {currentPost.author}</a></li>
                          <li><a href="#"> <i className="bi bi-clock" style={{ marginRight: '8px' }} />
                            {formatDate(new Date(currentPost.published_date), { addSuffix: true })}</a></li>
                        </ul>
                        <div className="title">
                          <h2 className="h4">{currentPost.title}</h2>
                        </div>
                      </div>
                      <div dangerouslySetInnerHTML={{ __html: currentPost.content }} />
                    </div>
                  </div>

                  <div className="post--social pbottom--30">
                    <span className="title"><i className="fa fa-share-alt" /></span>
                    <div className="social--widget style--4">
                      <ul className="nav">
                        <li>
                          <a href={`https://www.facebook.com/sharer/sharer.php?u=https://dailynewscrypto.net/news/${currentPost.news_id}`} target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href={`https://twitter.com/intent/tweet?url=https://dailynewscrypto.net/news/${currentPost.news_id}&text=${encodeURIComponent(currentPost.title)}`} target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href={`https://www.linkedin.com/shareArticle?url=https://dailynewscrypto.net/news/${currentPost.news_id}&title=${encodeURIComponent(currentPost.title)}`} target="_blank" rel="noopener noreferrer">
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              {/* Related Posts */}
              {relatedPosts.length > 0 && (
                <div className="post--related ptop--30 container">
                  <div className="post--items-title b">
                    <h2 className="h4">You Might Also Like</h2>
                  </div>
                  <div className="post--items post--items-2">
                    <ul className="nav row">
                      {relatedPosts.slice(0, 2).map((post) => (
                        <li className="col-sm-4 pbottom--30" key={post.news_id}>
                          <div className="post--item post--layout-1">
                            <div className="post--img">
                              <a href={`/news/${post.news_id}`} className="thumb">
                                <img src={`https://dailynewscrypto.net${post.image}`} alt={post.title} />
                              </a>
                              <a href="#" className="cat">{post.category}</a>
                            </div>
                            <div className="post--info">
                              <ul className="nav meta">
                                <li><a href="#">{post.author}</a></li>
                                <li><a href="#">{formatDistanceToNow(new Date(post.published_date), { addSuffix: true })}</a></li>
                              </ul>
                              <div className="title">
                                <h3 className="h4">
                                  <a href={`/news/${post.news_id}`} className="btn-link">
                                    {post.title}
                                  </a>
                                </h3>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;
