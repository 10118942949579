import React from "react";

const Footer = () => {
  return (
    <footer className="footer--section">
    
      {/* Footer Copyright Start */}
      <div className="footer--copyright bg--color-3 text-light bg--color-1 ">
        <div className="social--bg bg--color-1 text-white"></div>

        <div className="container bg--color-1  ">
          <p className="text float--left">
            &copy; 2025 <a href="https://dailynewscrypto.net" className=" bg-white" style={{ color: 'white' }}>
              dailynewscrypto.net
            </a> - All Rights Reserved.
          </p>

          <ul className="nav social float--right">
            <li>
              <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* Footer Copyright End */}
    </footer>
  );
};

export default Footer;
