import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom"; // Import Link for routing
import { Helmet } from "react-helmet"; // Import Helmet for SEO
import { formatDistanceToNow } from "date-fns"; // Import function for time ago formatting
import { enUS } from "date-fns/locale";
  // Custom locale to remove "about" from the date string
  const customLocale = {
    ...enUS,
    formatDistance: (token, count, options) => {
      const formattedString = enUS.formatDistance(token, count, options);
      return formattedString.replace(/^about\s/, ""); // Remove "about"
    },
  };
const CryptoResourcesDirectory = () => {
  const [resources, setResources] = useState([]);
  const [visibleCards, setVisibleCards] = useState(6); // Initially display 6 cards

  


 // Number of posts to display initially
  const formatDate = (date) => {
     const parsedDate = new Date(date);
     return isNaN(parsedDate.getTime())
       ? "Date not available"
       : formatDistanceToNow(parsedDate, { addSuffix: true, locale: customLocale });
   };

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://dailynewscrypto.net/api.php?endpoint=resources",{
          cache: 'no-store',  // Disable cache
        });
        setResources(response.data);
      } catch (error) {
        console.error("Error fetching resources:", error);
      }
    };

    fetchData();
  }, []);

  const handleSeeMore = () => {
    setVisibleCards((prevVisibleCards) => prevVisibleCards + 3); // Show 3 more cards on each click
  };

  return (
    <>
      <Helmet>
        <title>Crypto Resources Directory</title>
        <meta
          name="description"
          content="Explore valuable resources in the tourism industry related to cryptocurrency. Stay updated with the latest trends and developments."
        />
        <meta
          name="keywords"
          content="tourism industry, crypto resources, cryptocurrency, crypto, tourism, industry trends, crypto news"
        />
        <meta property="og:title" content="Tourism Industry Crypto Resources" />
        <meta
          property="og:description"
          content="Explore valuable resources in the tourism industry related to cryptocurrency. Stay updated with the latest trends and developments."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://example.com/images/og-image.jpg" />
      </Helmet>

      <div className="wrapper">
        <div>
          <div className="wrapper">
            {/* Dynamic Breadcrumb Section */}
            {/* <div className="main--breadcrumb">
              <div className="container">
                <div className="ad--space pd--20-0-40">
                  <a href="#">
                    <img
                      src="img/ads-img/ad-728x90-02.jpg"
                      alt=""
                      className="center-block"
                    />
                  </a>
                </div>
              </div>
            </div> */}
            <div className="main--breadcrumb">
              <div className="container">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/" className="btn-link">
                      <i className="fa fm fa-home" />
                      Home
                    </Link>
                  </li>
                  <li className="active">
                    <span>Crypto Resources Directory</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Content Section */}
            <div className="main-content--section pbottom--30">
              <div className="container">
                <div className="row">
                  {/* Main Content */}
                  <div className="main--content col-md-8" data-sticky-content="true">
                    <div className="col-md-12 ptop--30 pbottom--30">
                      <div className="post--items-title">
                        <h2 className="h4">Crypto Resources Directory</h2>
                      </div>
                      <div className="post--items post--items-2">
                        <ul className="nav">
                          {resources.slice(0, visibleCards).map((resource, index) => (
                            <React.Fragment key={index}>
                              <li>
                                <div className="post--item">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="post--img">
                                        <Link to={`/resources/${resource.id}`} className="thumb">
                                          <img
                                            src={`https://dailynewscrypto.net/${resource.image_url}`}
                                            alt={resource.title}
                                            style={{ height: "172px" }} // Set image size
                                          />
                                        </Link>

                                        <a href="#" className="cat">
                                          <a href="#">
                                          {formatDate(new Date(resource.created_at))}
                                          </a>
                                        </a>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="post--info">
                                        <div className="title">
                                          <h3 className="h4">
                                            {/* Link to details page */}
                                            <Link to={`/resources/${resource.id}`} className="btn-link">
                                              {resource.title}
                                            </Link>
                                          </h3>
                                        </div>
                                      </div>
                                      <div className="post--content">
                                        <p>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                resource.content.slice(0, 100) +
                                                (resource.content.length > 100 ? "..." : ""),
                                            }}
                                          />
                                        </p>
                                      </div>
                                      <div className="post--action">
                                        <Link to={`/resources/${resource.id}`}>
                                          Continue Reading...
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              {index < visibleCards - 1 && index < resources.length - 1 && (
                                <li>
                                  <hr className="divider" />
                                </li>
                              )}
                            </React.Fragment>
                          ))}
                        </ul>
                        {visibleCards < resources.length && (
                          <div className="text-center mtop--30">
                            <button
                              onClick={handleSeeMore}
                              className="btn btn-primary mt-3"
                            >
                              See More
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Sidebar */}
                  <div className="main--sidebar col-md-4 ptop--30 pbottom--30" data-sticky-content="true">
                    <div className="sticky-content-inner">
                      {/* Newsletter Widget */}
                    </div>

                    {/* Widget End */}
                    {/* Widget Start */}
                    <div className="widget hidden-xss">
                      <div className="widget--title">
                        <h2 className="h4">Advertisement</h2>
                        <i className="icon fa fa-bullhorn" />
                      </div>
                      {/* Ad Widget Start */}
                      <div className="ad--widget">
                        <a href="#">
                          <img src="/img/ads-img/ad-300x250-2.jpg" alt="" />
                        </a>
                      </div>
                      {/* Ad Widget End */}
                    </div>
                    <div className="widget hidden-xss">
                      <div className="widget--title">
                        <h2 className="h4">Advertisement</h2>
                        <i className="icon fa fa-bullhorn" />
                      </div>
                      {/* Ad Widget Start */}
                      {/* <div className="ad--widget ">
                        <a href="#">
                          <img src="img/ads-img/ad-300x250-2.jpg" alt="" />
                        </a>
                      </div> */}
                      {/* Ad Widget End */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ad--space pd--20-0-40">
              <a href="#">
                <img
                  src="img/ads-img/ad-728x90-02.jpg"
                  alt=""
                  className="center-block"
                />
              </a>
            </div> */}
            {/* Related Posts Section */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoResourcesDirectory;
