import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { MdPadding } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons'; // Breaking news icon


const NewsTicker = () => {
  const [lastUpdated, setLastUpdated] = useState('10 minutes ago');
  const [newsItems, setNewsItems] = useState([]);

  // Fetch news data from the API
  useEffect(() => {
    const fetchNews = async () => {
      try {
        // Replace with your backend API URL
        const response = await fetch('https://dailynewscrypto.net/api.php?endpoint=news'); 
        const data = await response.json();
        const limitedData = data.slice(0, 10); 
        setNewsItems(limitedData);  // Update state with limited news items
      
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();

    // Update the "lastUpdated" value periodically if needed
    const timer = setInterval(() => {
      setLastUpdated('10 minutes ago'); // Replace with logic to compute time dynamically
    }, 600000); // Update every minute

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="news--ticker">
  
  
      <div className="container">
        <div className="title">
          <h2>News Updates</h2>
          <span>(Update {lastUpdated})</span>
        </div>

        <div className="news-updates--list  "  id="marquee" data-marquee="true">
          <ul className="nav marquee-content" >
            {newsItems.map((item, index) => (
              <li key={index}>
                <p className="h3 d-flex">
                  <Link to={`/news/${item.news_id}`}  rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faBolt} className="breaking-news-icon "
                     style={{ color: '#6666cc', marginRight: '8px' }} />

                    {item.title}
               
                  </Link>
                  
                </p>
                {/* Optionally show the news content or an excerpt */}
                <p>{item.title}...</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NewsTicker;
