import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { encodeURL } from './encodeURL';

// Utility functions
const truncateText = (text, maxLength) => {
  if (!text) return ''; // Handle empty or null text
  const ellipsis = '...';
  return text.length > maxLength
    ? text.slice(0, maxLength - ellipsis.length) + ellipsis
    : text;
};

const stripHtmlTags = (text) => {
  if (!text) return ''; // Handle empty or null text
  return text.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
};

const validateImageUrl = (url) =>
  url && url.startsWith('http') ? url : 'https://miro.medium.com/max/812/1*1xhuVp8f2WFUGUByHS8VTg.png'; // Fallback to default image

const Seo = ({ title, description, imageUrl, url }) => {
  const maxTitleLength = 60; // Recommended limit for SEO
  const maxDescriptionLength = 160;

  // Sanitize and truncate the title and description
  const filteredTitle = truncateText(stripHtmlTags(title), maxTitleLength);
  const filteredDescription = truncateText(stripHtmlTags(description), maxDescriptionLength);

  // Ensure valid image URL and encode it
  const validatedImageUrl = validateImageUrl(imageUrl);
  const encodedImageURL = encodeURL(validatedImageUrl);
console.log(encodedImageURL)
  return (
    <Helmet>
      <title>{filteredTitle}</title>

      {/* General meta description */}
      <meta name="description" content={filteredDescription} />

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={filteredTitle} />
      <meta property="og:description" content={filteredDescription} />
      <meta property="og:image" content={encodedImageURL} />
      <meta property="og:image:alt" content="Detailed analysis of blockchain, cryptocurrency, and NFT trends" /> {/* More descriptive alt text */}
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={filteredTitle} />
      <meta name="twitter:description" content={filteredDescription} />
      <meta name="twitter:image" content={encodedImageURL} />
      <meta name="twitter:image:alt" content="Detailed analysis of blockchain, cryptocurrency, and NFT trends" /> {/* More descriptive alt text */}
      <meta name="twitter:creator" content="@dnc__net" />
      <meta name="twitter:url" content={url} />

      {/* Canonical Link */}
      <link rel="canonical" href={url} />
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired, // Ensure `url` is required
};

Seo.defaultProps = {
  description: 'Default description for SEO purposes if no description is provided.',
  imageUrl: 'https://miro.medium.com/max/812/1*1xhuVp8f2WFUGUByHS8VTg.png', // Default image URL if missing
};

export default Seo;
