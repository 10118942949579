import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { formatDistanceToNow } from 'date-fns'; // Import function for time ago formatting
import { enUS } from "date-fns/locale";
  
  // Custom locale to remove "about" from the date string
  const customLocale = {
    ...enUS,
    formatDistance: (token, count, options) => {
      const formattedString = enUS.formatDistance(token, count, options);
      return formattedString.replace(/^about\s/, ""); // Remove "about"
    },
  };
const PostItems = () => {
 const formatDate = (date) => {
     const parsedDate = new Date(date);
     return isNaN(parsedDate.getTime())
       ? "Date not available"
       : formatDistanceToNow(parsedDate, { addSuffix: true, locale: customLocale });
   };
 
  const [posts, setPosts] = useState([]);
  const [displayCount, setDisplayCount] = useState(6); // Number of posts to display initially

  useEffect(() => {
    // Fetch all posts from the API
    fetch('https://dailynewscrypto.net/api.php?endpoint=news',{
      cache: 'no-store',  // Disable cache
    })
      .then((response) => response.json())
      .then((data) => {
        // Filter posts where category is 'DeFi'
        const filteredPosts = data.filter(post => post.category === 'DeFi');
        setPosts(filteredPosts);
      })
      .catch((error) => console.error('Error fetching posts:', error));
  }, []);

  const handleSeeMoreClick = () => {
    setDisplayCount((prevCount) => prevCount + 3); // Show 3 more posts on each click
  };

 
  return (
    <>
      {/* Helmet for SEO */}
   
      <div className="">
        {/* Post Items Title Start */}
        <div className="post--items-title" data-ajax="tab">
          <h2 className="h4">DeFi</h2>
        </div>
        <div className="post--items post--items-3" data-ajax-content="outer">
          <ul className="nav" data-ajax-content="inner">
            {posts.length > 0 ? (
              posts.slice(0, displayCount).map((post, index) => (
                <li key={post.news_id}>
                  <div className={`post--item post--layout-${index === 0 ? '1' : '3'}`}>
                    <div className="post--img">
                      <Link to={`/news/${post.news_id}`} className="thumb">
                        <img src={`https://dailynewscrypto.net${post.image}`} alt={post.title} />
                      </Link>
                      {index === 0 && (
                        <Link to="#" className="cat">{post.category}</Link>
                      )}
                      <div className="post--info">
                        <ul className="nav meta">
                          <li><Link to="#">{post.author}</Link></li>
                          <li><Link to="#">{formatDate(post.published_date)}</Link></li>
                        </ul>
                        <div className="title">
                          <h3 className="h4 pbottom--30">
                            <Link to={`/news/${post.news_id}`} className="btn-link">{post.title}</Link>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p>No DeFi-related news available.</p>
            )}
          </ul>
          <br />
          {posts.length > displayCount && (
            <div className="text-center">
              <button className="mt-4 btn btn-primary" onClick={handleSeeMoreClick}>See More</button>
            </div>
          )}
          <div className="preloader bg--color-0--b" data-preloader={1}>
            <div className="preloader--inner" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PostItems;
