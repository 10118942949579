import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import axios from 'axios';
import { formatDistanceToNow } from 'date-fns'; // Import date-fns

import { enUS } from "date-fns/locale";

// Custom locale to remove "about" from the date string
const customLocale = {
  ...enUS,
  formatDistance: (token, count, options) => {
    const formattedString = enUS.formatDistance(token, count, options);
    return formattedString.replace(/^about\s/, ""); // Remove "about"
  },
};

const formatDate = (date) => {
  const parsedDate = new Date(date);
  return isNaN(parsedDate.getTime())
    ? "Date not available"
    : formatDistanceToNow(parsedDate, { addSuffix: true, locale: customLocale });
};
 
const PostItem = ({ image, category, author, date, title, link }) => (

  <div className="post--item post--layout-1">
    <div className="post--img">
      <Link to={link} className="thumb">
        <img src={image} alt={title} />
      </Link>
      <a href="#" className="cat">{category}</a>
      <div className="post--info">
        <ul className="nav meta">
          <li><a href="#">{author}</a></li>
          <li>
            <a href="#">
              {formatDate(new Date(date), { addSuffix: true })}
            </a>
          </li>
        </ul>
        <div className="title">
          <h2 className="h4">
            <Link to={link} className="btn-link">{title}</Link>
          </h2>
        </div>
      </div>
    </div>
  </div>
);

const AdSpace = ({ image, link }) => (
  <div className="ad--space pd--30-0">
    <a href={link}>
      <img src={image} alt="Advertisement" className="center-block" />
    </a>
  </div>
);

const PostItems = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    axios.get('https://dailynewscrypto.net/api.php?endpoint=news')
      .then(response => {
        setPosts(response.data);
      })
      .catch(error => {
        console.error('Error fetching news:', error);
      });
  }, []);

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
        <title>Latest Cryptocurrency News and Updates</title>
        <meta
          name="description"
          content="Discover the latest trends, news, and insights in the cryptocurrency market. Stay informed with up-to-date articles."
        />
        <meta property="og:title" content="Latest Cryptocurrency News and Updates" />
        <meta
          property="og:description"
          content="Your source for recent cryptocurrency news and updates. Explore trends and insights."
        />
        <meta property="og:image" content={posts[0]?.image ? `https://dailynewscrypto.net${posts[0].image}` : 'http://localhost:5001/static/default-image.png'} />
        <meta property="og:url" content="https://dailynewscrypto.net" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="main--content">
        <div className="post--items post--items-1 pd--30-0">
          <div className="row gutter--15">
            <div className="col-md-6">
              {posts[0] && (
                <PostItem 
                  image={`https://dailynewscrypto.net${posts[0].image}`} 
                  category={posts[0].category} 
                  author={posts[0].author} 
                  date={posts[0].published_date} 
                  title={posts[0].title} 
                  link={`/news/${posts[0].news_id}`} 
                />
              )}
            </div>
            <div className="col-md-6">
              <div className="row gutter--15">
                {posts.slice(1, 5).map((post, index) => (
                  <div className="col-xs-6 col-xss-12" key={index}>
                    <PostItem 
                      image={`https://dailynewscrypto.net${post.image}`} 
                      category={post.category} 
                      author={post.author} 
                      date={post.published_date} 
                      title={post.title} 
                      link={`/news/${post.news_id}`} 
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AdSpace image="/img/ads-img/ad-970x90.jpg" link="#" /> */}
    </div>
  );
};

export default PostItems;
