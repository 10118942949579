import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import { formatDistanceToNow } from 'date-fns'; // Import function for time ago formatting
import LearningCenterForm from './LearningCenterForm'; // Form Component
import { enUS } from "date-fns/locale";
  // Custom locale to remove "about" from the date string
  const customLocale = {
    ...enUS,
    formatDistance: (token, count, options) => {
      const formattedString = enUS.formatDistance(token, count, options);
      return formattedString.replace(/^about\s/, ""); // Remove "about"
    },
  };
function App() {
  const [visibleItems, setVisibleItems] = useState(9); // Start with 9 items
  const [items, setItems] = useState([]); // State to hold the fetched items


  
   // Number of posts to display initially
    const formatDate = (date) => {
       const parsedDate = new Date(date);
       return isNaN(parsedDate.getTime())
         ? "Date not available"
         : formatDistanceToNow(parsedDate, { addSuffix: true, locale: customLocale });
     };

  // Fetch data from the backend on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://dailynewscrypto.net/api.php?endpoint=learning-center",{
          cache: 'no-store',  // Disable cache
        }); // Update URL if needed
        const data = await response.json();
        setItems(data); // Set fetched data to items state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Handle the "See More" button click to show more items
  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 5); // Increase by 5
  };

  return (
    <>
      <Helmet>
        <title>Learning Center | dailynewscrypto.net</title>
        <meta
          name="description"
          content="Explore the Learning Center at dailynewscrypto.net. Discover articles and tutorials about cryptocurrency, blockchain, and the latest trends in the crypto space."
        />
        <meta
          name="keywords"
          content="learning center, cryptocurrency tutorials, crypto articles, blockchain learning, crypto education"
        />
        <meta name="author" content="dailynewscrypto.net" />
        <meta property="og:title" content="Learning Center | dailynewscrypto.net" />
        <meta
          property="og:description"
          content="Visit the Learning Center at dailynewscrypto.net for in-depth articles and resources on cryptocurrency and blockchain technology."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://dailynewscrypto.net/learning-center" />
        <meta property="og:image" content="https://dailynewscrypto.net/images/learning-center-banner.jpg" />
      </Helmet>

      <div>
        <div className="wrapper">
          {/* Header Section */}
          {/* Main Breadcrumb */}
          {/* <div className="container">
            <div className="ad--space pd--20-0-40">
              <a href="#">
                <img
                  src="img/ads-img/ad-728x90-02.jpg"
                  alt=""
                  className="center-block"
                />
              </a>
            </div>
          </div> */}
        
          <div className="main--breadcrumb">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/" className="btn-link">
                    <i className="fa fm fa-home" />
                    Home
                  </Link>
                </li>
                <li className="active">
                  <span>Learning Center</span>
                </li>
              </ul>
            </div>
          </div>

          {/* Main Content Section */}
          <div className="main-content--section pbottom--30">
            <div className="container">
              <div className="row">
                <div className="col-md-12 ptop--30 pbottom--30">
                  <div className="post--items-title">
                    <h2 className="h4">Learning Center</h2>
                  </div>
                  <div className="post--items post--items-1">
                    <ul className="nav row gutter--15">
                      {items.slice(0, visibleItems).map((item) => (
                        <li key={item.id} className="col-md-4 col-xs-6 col-xxs-12">
                          <div className="post--item post--layout-1">
                            <div className="post--img">
                              <Link to={`/LearningCenterDetail/${item.id}`} className="thumb">
                                <img
                                  src={`https://dailynewscrypto.net${item.image}`}
                                  alt={item.title}
                                />
                              </Link>
                              <div className="post--info">
                                <div className="title">
                                  <h2 className="h4">
                                    <Link to={`/LearningCenterDetail/${item.id}`} className="btn-link">
                                      {item.title}
                                    </Link>
                                  </h2>
                                </div>
                                <ul className="nav meta">
                                  <li>
                                    <a href="#">
                                    {formatDate(new Date(item.created_at))}
                             
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>

                    {/* "See More" Button */}
                    {visibleItems < items.length && (
                      <div className="col-md-12 text-center mtop--30">
                        <button onClick={handleLoadMore} className="btn btn-primary mt-3">
                          See More
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Learning Center Form */}
      </div>
    </>
  );
}

export default App;
