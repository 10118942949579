import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import LogoImage from "./logo.svg"
import Adsnav from "./ad-728x90-01.jpg"
import "./header.js"

const Header = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeLink, setActiveLink] = useState("/"); // Track the active link
  const location = useLocation(); // Get the current location

  // Update the active link based on the current location
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  // Fetch data from the API
  useEffect(() => {
    if (!searchTerm.trim()) {
      setSearchResults([]);
      setFilteredResults([]);
      return; // Do nothing if search term is empty
    }

    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      setError(null); // Reset previous errors

      // Make the API request
      axios
        .get(`https://dailynewscrypto.net/api.php?endpoint=news`)
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((err) => {
          setError("Failed to fetch search results.");
          console.error("Error fetching search results:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 500); // Wait for 500ms after the user stops typing before sending the request

    // Clean up the timeout on component unmount or when searchTerm changes
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  // Filter results based on searchTerm
  useEffect(() => {
    if (searchTerm.trim() && searchResults.length > 0) {
      const filtered = searchResults.filter((news) => {
        // Ensure that both title and description exist and are strings before using toLowerCase
        const titleMatch = news.title && news.title.toLowerCase().includes(searchTerm.toLowerCase());
        const descriptionMatch = news.description && news.description.toLowerCase().includes(searchTerm.toLowerCase());
  
        return titleMatch || descriptionMatch;
      });
      setFilteredResults(filtered);
    } else {
      setFilteredResults([]);
    }
  }, [searchTerm, searchResults]);
  

  // Handle search result click
  const handleSearchResultClick = () => {
    setSearchTerm(""); // Clear search term
    setFilteredResults([]); // Clear search results
  };

  return (
    <div className="wrapper">
      <header className="header--section header--style-1">
        {/* SEO Meta Tags */}
     
        <div className="header--mainbar">
          <div className="container">
            <div className="header--logo float--left float--sm-none text-sm-center">
              <h1 className="h1">
                <a href="/" className="btn-link">
                  <img src={LogoImage} width="80px" />
                </a>
              </h1>
            </div>
            <div className="header--ad float--right float--sm-none hidden-xs">
              <a href="#">
                <img src={Adsnav} alt="Advertisement" />
              </a>
            </div>
          </div>
        </div>

        <div className="header--navbar style--1 navbar bd--color-1 bg--color-1" data-trigger="sticky">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#headerNav"
                aria-expanded="false"
                aria-controls=""
              >
                <span className="sr-only">Toggle Navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>

            <div id="headerNav" className="navbar-collapse collapse float--left">
              <ul className="header--menu-links nav navbar-nav" data-trigger="hoverIntent">
                <li className={activeLink === "/" ? "active" : ""}>
                  <Link to="/">Home</Link>
                </li>
                <li className={activeLink === "/LearningCenter" ? "active" : ""}>
                  <Link to="/LearningCenter">Learning Center</Link>
                </li>
                <li className={activeLink === "/CryptoResourcesDirectory" ? "active" : ""}>
                  <Link to="/CryptoResourcesDirectory">Crypto Resources & Directory</Link>
                </li>
                <li className={activeLink === "/CryptoPriceTable" ? "active" : ""}>
                  <Link to="/CryptoPriceTable">Crypto Prices</Link>
                </li>
                <li className={activeLink === "/Exchanges" ? "active" : ""}>
                  <Link to="/Exchanges">Exchanges </Link>
                </li>
                <li className={activeLink === "/about" ? "active" : ""}>
                  <Link to="/about">About Us</Link>
                </li>
                <li className={activeLink === "/contact" ? "active" : ""}>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="header--search-form float--right">
              <input
                type="search"
                name="search"
                placeholder="Search..."
                className="header--search-control form-control"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                required
              />
              <button type="submit" className="header--search-btn btn">
                <i className="header--search-icon fa fa-search"></i>
              </button>
            </div>
            {loading && <div className="loading-indicator"></div>} {/* Show loading state */}

            {error && <div className="error-message">{error}</div>} {/* Show error message */}

            {filteredResults.length > 0 && (
              <div className="search-results">
                <ul>
                  {filteredResults.map((news, index) => (
                    <li key={index}>
                      <Link
                        to={`/news/${news.news_id}`}
                        className="thumb"
                        onClick={handleSearchResultClick}
                      >
                        <img
                          src={`https://dailynewscrypto.net${news.image}`}
                          alt={news.title}
                          width="50px"
                        />
                        <span>{news.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
