import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './style.css';
import { Link } from "react-router-dom"; // Import Link for routing
import CryptoTicker from './CryptoTicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

const CryptoPriceTable = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [sortConfig, setSortConfig] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch cryptocurrency data
  const fetchCryptoData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/coins/markets', {
        params: {
          vs_currency: 'usd',
          order: 'market_cap_desc',
          per_page: 1000,
          page: currentPage,
        },
      });
      console.log(response.data);  // Log the response to inspect available fields
      setCryptoData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCryptoData();

    // Set an interval to fetch data every 30 seconds
    const intervalId = setInterval(() => {
      fetchCryptoData();
    }, 30000); // 30 seconds interval for live price updates

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentPage]);

  // Sort function
  const sortData = (key) => {
    let sortedData = [...cryptoData];
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      sortedData.reverse();
      setSortConfig({ key, direction: 'descending' });
    } else {
      sortedData.sort((a, b) => {
        if (typeof a[key] === 'string') {
          return a[key].localeCompare(b[key]);
        } else {
          return a[key] - b[key]; // For numerical values like price, market cap, etc.
        }
      });
      setSortConfig({ key, direction: 'ascending' });
    }
    setCryptoData(sortedData);
  };

  // Filter function
  const filteredData = cryptoData.filter((coin) =>
    coin.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Format numbers with dynamic precision and include "$" symbol
  const formatNumber = (value) => {
    if (value === undefined || value === null) return 'N/A';
    if (value < 0.01) return `$${value.toFixed(6)}`; // Format small numbers with 6 decimal places
    return `$${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
  };

  // Render table rows
  const renderTableRows = () => {
    return currentItems.map((coin, index) => {
      const priceChange24h = coin.price_change_percentage_24h;
      const priceChange7d = coin.price_change_percentage_7d || 0;  // Use the correct field here
      const rowNumber = (currentPage - 1) * itemsPerPage + index + 1;

      return (
        <tr key={coin.id}>
          <td>{rowNumber}</td>
          <td>
            <div className="d-flex align-items-center" id='dFlex'>
              <img src={coin.image} alt={coin.name} className="icons mr-3" />
              <span className="ml-3">{coin.name}</span>
            </div>
          </td>
          <td>{formatNumber(coin.current_price)}</td>
          <td>{formatNumber(coin.market_cap)}</td>
          <td>{formatNumber(coin.total_volume)}</td>
          <td>
            <span style={{ color: priceChange24h > 0 ? 'green' : 'red' }}>
              {priceChange24h > 0 ? '▲' : '▼'} {priceChange24h?.toFixed(2)}%
            </span>
          </td>
          <td>{formatNumber(coin.circulating_supply)}</td>
        </tr>
      );
    });
  };

  return (
    <>
      <Helmet>
        <title>Crypto Prices - Latest Cryptocurrency Prices and Market Data</title>
        <meta name="description" content="Get the latest cryptocurrency prices, market cap, volume, and more for top cryptocurrencies including Bitcoin, Ethereum, and more." />
        <meta name="keywords" content="cryptocurrency, crypto prices, bitcoin, ethereum, market cap, price change, crypto news" />
        <meta property="og:title" content="Crypto Prices - Latest Cryptocurrency Prices" />
        <meta property="og:description" content="Get the latest cryptocurrency prices, market cap, volume, and more for top cryptocurrencies including Bitcoin, Ethereum, and more." />
        <meta property="og:image" content="path-to-image.jpg" /> {/* Replace with an actual image URL */}
        <meta property="og:url" content="https://yourdomain.com/crypto-prices" />
        <meta name="twitter:title" content="Crypto Prices - Latest Cryptocurrency Prices" />
        <meta name="twitter:description" content="Get the latest cryptocurrency prices, market cap, volume, and more for top cryptocurrencies including Bitcoin, Ethereum, and more." />
        <meta name="twitter:image" content="path-to-image.jpg" /> {/* Replace with an actual image URL */}
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="wrapper">
        <div>
          <CryptoTicker />
          <div className="wrapper">
            {/* Dynamic Breadcrumb Section */}
            <div className="main--breadcrumb">
              <div className="container">
                {/* <div className="ad--space pd--20-0-40">
                  <a href="#">
                    <img src="img/ads-img/ad-728x90-02.jpg" alt="" className="center-block" />
                  </a>
                </div> */}
              </div>
            </div>
            <div className="main--breadcrumb">
              <div className="container">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/" className="btn-link">
                      <i className="fa fm fa-home" />
                      Home
                    </Link>
                  </li>
                  <li className="active">
                    <span>Crypto Prices</span>
                  </li>
                </ul>
              </div>
            </div>

            {/* Content Section */}
            <div className="main-content--section pbottom--30">
              <div className="container">
                <div className="row">
                  {/* Main Content */}
                  <div className="main--content col-md-8" data-sticky-content="true">
                    <div className="col-md-12 ptop--30 pbottom--30">
                      <div className="post--items-title">
                        <h2 className="h4">Crypto Prices</h2>
                      </div>
                      <div className="input-group d-flex align-items-center">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by name"
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="input-group-append">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faSearch} />
                          </span>
                        </div>
                      </div>
                      <div className="post--items post--items-2">
                        <div className="bg-white px-2 pt-1 mt-2">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th onClick={() => sortData('name')}>Name</th>
                                  <th onClick={() => sortData('current_price')}>Price</th>
                                  <th onClick={() => sortData('market_cap')}>Market Cap</th>
                                  <th onClick={() => sortData('total_volume')}>Volume (24h)</th>
                                  <th onClick={() => sortData('price_change_percentage_24h')}>Change (24h)</th>
                                  <th>Circulating Supply</th>
                                </tr>
                              </thead>
                              <tbody>
                                {loading ? (
                                  <tr>
                                    <td colSpan="8" className="text-center">Loading...</td>
                                  </tr>
                                ) : (
                                  renderTableRows()
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* Pagination */}
                        <div className="d-flex justify-content-center text-center gap-3 mtop--30" id="PaginationdFlex">
                          <button
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="btn btn-primary"
                          >
                            <span className="glyphicon glyphicon-chevron-left"></span>
                          </button>
                          <button
                            onClick={() => paginate(currentPage + 1)}
                            disabled={filteredData.length < itemsPerPage}
                            className="btn btn-primary"
                          >
                            <span className="glyphicon glyphicon-chevron-right"></span>
                          </button>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>

                  {/* Sidebar */}
                  <div className="main--sidebar col-md-4 ptop--30 pbottom--30" data-sticky-content="true">
                    <div className="sticky-content-inner">
                      {/* Newsletter Widget */}
                    </div>

                    {/* Widget End */}
                    {/* Widget Start */}

                    <div className="widget hidden-xss">
                      <div className="widget--title">
                        <h2 className="h4">Advertisement</h2>
                        <i className="icon fa fa-bullhorn" />
                      </div>
                      {/* Ad Widget Start */}
                      <div className="ad--widget">
                        <a href="#">
                          <img src="img/ads-img/ad-300x250-2.jpg" alt="" />
                        </a>
                      </div>
                      {/* Ad Widget End */}
                    </div>
                    <div className="widget hidden-xss">
                      <div className="widget--title">
                        <h2 className="h4">Advertisement</h2>
                        <i className="icon fa fa-bullhorn" />
                      </div>
                      {/* Ad Widget Start */}
                      <div className="ad--widget">
                        <a href="#">
                          <img src="img/ads-img/ad-300x250-2.jpg" alt="" />
                        </a>
                      </div>
                      {/* Ad Widget End */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ad--space pd--20-0-40">
              <a href="#">
                <img src="img/ads-img/ad-728x90-02.jpg" alt="" className="center-block" />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoPriceTable;
