import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import NewsFirstSections from './NewsFirstSections';
import NewsSecondSections from './NewsSecondSections';
import Ads from './Ads';
import Detail from './detail';

import NewsTicker from './NewsTicker';

function App() {
  return (
    <div className="main-content--section pbottom--30">
      {/* Global Helmet for default SEO */}
    
      <NewsTicker />
      <div className="container">
        <Routes>
          {/* Detail Page */}
          <Route
            path="/news/:id"
            element={
              <>
              
                <Detail />
              </>
            }
          />

          {/* Home Page */}
          <Route
            path="/"
            element={
              <>
                
                <NewsFirstSections />
                <NewsSecondSections />
                <Ads />
              </>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
