import "./Styling.css";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet"; // Importing React Helmet for managing metadata

const CryptoExchangesTable = () => {
  const [exchanges, setExchanges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortConfig, setSortConfig] = useState({ key: "rank", direction: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const [currency, setCurrency] = useState("USD");
  const itemsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.coingecko.com/api/v3/exchanges");
        const data = await response.json();
        setExchanges(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching exchanges:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedExchanges = [...exchanges].sort((a, b) => {
      const aValue = a[key] || "";
      const bValue = b[key] || "";

      if (direction === "asc") {
        return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
      }
      return aValue < bValue ? 1 : aValue > bValue ? -1 : 0;
    });

    setExchanges(sortedExchanges);
  };

  const getSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return "";
  };

  const filteredExchanges = exchanges.filter((exchange) =>
    exchange.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentExchanges = filteredExchanges.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(filteredExchanges.length / itemsPerPage);

  return (
    <div className="wrapper">
      <Helmet>
        <title>Top Crypto Exchanges | Find Crypto Exchange Information</title>
        <meta
          name="description"
          content="Explore the top cryptocurrency exchanges globally. Get detailed information on exchange rankings, country of origin, and more."
        />
        <meta
          name="keywords"
          content="cryptocurrency, exchanges, top crypto exchanges, exchange rankings, crypto exchange comparison"
        />
      </Helmet>

      <div className="main--breadcrumb container">
        <div className="container">
          <ul className="breadcrumb">
            <li>
              <Link to="/" className="btn-link">
                <i className="fa fm fa-home" />
                Home
              </Link>
            </li>
            <li className="active">
              <span>Crypto Exchanges</span>
            </li>
          </ul>
        </div>
      </div>

      <div className="main-content--section pbottom--30">
        <div className="container">
          <div className="row">
            <div className="main--content col-md-8">
              <div className="col-md-12 ptop--30 pbottom--30">
                <div className="post--items-title">
                  <h2 className="h4">Top Crypto Exchanges</h2>
                </div>

                <div id="crypto-exchange-controls" className="d-flex">
                  <div id="search-container">
                    <input
                      type="text"
                      placeholder="Search exchanges..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <span className="search-icon">🔍</span>
                  </div>
                </div>

                <table id="crypto-exchange-table">
                  <thead>
                    <tr>
                      <th onClick={() => handleSort("rank")}>
                        # {getSortArrow("rank")}
                      </th>
                      <th>Icon</th>
                      <th onClick={() => handleSort("name")}>
                        Exchange {getSortArrow("name")}
                      </th>
                      <th onClick={() => handleSort("year_established")}>
                        Year Established {getSortArrow("year_established")}
                      </th>
                      <th onClick={() => handleSort("country")}>
                        Country {getSortArrow("country")}
                      </th>
                      <th onClick={() => handleSort("change")}>
                        Change (24h) {getSortArrow("change")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentExchanges.map((exchange, index) => (
                      <tr key={exchange.id}>
                        <td>{firstIndex + index + 1}</td>
                        <td>
                          <img
                            src={exchange.image}
                            alt={exchange.name}
                            style={{
                              width: "30px",
                         
                              borderRadius: "50%",
                            }}
                          />
                        </td>
                        <td>
                          <a
                            href={`https://www.coingecko.com/en/exchanges/${exchange.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {exchange.name}
                          </a>
                        </td>
                        <td>{exchange.year_established || "N/A"}</td>
                        <td>{exchange.country || "N/A"}</td>
                        <td>{`${(Math.random() * 100).toFixed(2)}%`}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div id="pagination-controls">
                  <button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    className="btn btn-primary"
                  >
                    <span className="glyphicon glyphicon-chevron-left"></span>
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className="btn btn-primary"
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                  >
                    <span className="glyphicon glyphicon-chevron-right"></span>
                  </button>
                </div>
              </div>
            </div>

            {/* <div className="main--sidebar col-md-4">
              <div className="widget">
                <div className="widget--title">
                  <h2 className="h4">Advertisement</h2>
                  <i className="icon fa fa-bullhorn" />
                </div>
                <div className="ad--widget">
                  <a href="#">
                    <img src="img/ads-img/ad-300x250-2.jpg" alt="Advertisement" />
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="ad--space pd--20-0-40">
        <a href="#">
          <img
            src="img/ads-img/ad-728x90-02.jpg"
            alt="Advertisement"
            className="center-block"
          />
        </a>
      </div>
    </div>
  );
};

export default CryptoExchangesTable;
