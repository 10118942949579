import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from "./Components/layout/navbar";
import NewsTicker from './Components/news/NewsTicker';
import MainNews from './Components/news/MainNews';
import Footer from './Components/layout/Footer';
import LearningCenterDetail from './Components/Learning Center/LearningCenterDetail';
import LearningCenter from './Components/Learning Center/Learning Center';
import CryptoResourcesDirectory from './Components/Crypto Resources & Directory/Crypto Resources & Directory';
import ResourceDetailPage from './Components/Crypto Resources & Directory/ResourceDetailPage';
import CryptoPriceTable from './Components/Crypto price/Crypto Price';
import AboutPage from './Components/about';
import Exchanges from './Components/Exchanges/Exchanges';
import ContactSection from './Components/Contact';
import NewsDetails from './Components/news/detail';
import 'bootstrap-icons/font/bootstrap-icons.css';
import NotFound from './Components/NotFound';

function App() {
  return (
    <div className="">
     
      <Navbar />
      <Routes>
        <Route path="/" element={<MainNews />} />
        <Route path="/LearningCenter" element={<LearningCenter />} />
        <Route path="/Exchanges" element={<Exchanges />} />
        <Route path="/CryptoResourcesDirectory" element={<CryptoResourcesDirectory />} />
        <Route path="/LearningCenterDetail/:id" element={<LearningCenterDetail />} />
        <Route path="/contact" element={<ContactSection />} />
        <Route path="/CryptoPriceTable" element={<CryptoPriceTable />} />
        <Route path="/resources/:id" element={<ResourceDetailPage />} />
        <Route path="/news/:id" element={<NewsDetails />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
      <div id="backToTop">
        <a href="#"><i className="fa fa-angle-double-up"></i></a>
      </div>
      <Footer />
    </div>
  );
}

export default App;
