import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import emailjs from 'emailjs-com';

const ContactSection = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    website: '',
    message: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send email using EmailJS
    emailjs
      .sendForm(
        'service_p8f7v0r',  // replace with your EmailJS service ID
        'template_qvv8dmj',  // replace with your EmailJS template ID
        e.target,             // the form element
        'rH0Ol91wSUYRTy73y'       // replace with your EmailJS user ID
      )
      .then(
        (result) => {
          console.log('Email sent successfully:', result.text);
          alert('Your message has been sent successfully!');
        },
        (error) => {
          console.log('Email sending error:', error.text);
          alert('There was an error sending your message. Please try again.');
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>Contact Us | dailynewscrypto.net</title>
        <meta
          name="description"
          content="Get in touch with dailynewscrypto.net. Reach out for inquiries, support, or collaborations regarding cryptocurrency news and trends."
        />
        <meta
          name="keywords"
          content="contact dailynewscrypto.net, cryptocurrency contact, crypto support, crypto news inquiries"
        />
        <meta name="author" content="dailynewscrypto.net" />
        <meta property="og:title" content="Contact Us | dailynewscrypto.net" />
        <meta
          property="og:description"
          content="Contact dailynewscrypto.net for the latest cryptocurrency news and updates."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://dailynewscrypto.net/contact" />
        <meta property="og:image" content="https://dailynewscrypto.net/images/contact-us-banner.jpg" />
      </Helmet>
      <div className="contact--section pd--30-0">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-4 ptop--30 pbottom--30">
              <div className="contact--info">
                <ul className="nav">
                  <li>
                    <div className="title">
                      <h3 className="h5"><i className="fa fa-envelope-open"></i>E-mail Address</h3>
                    </div>
                    <div className="content">
                      <p><a href="mailto:info@dailynewscrypto.net">info@dailynewscrypto.net</a></p>
                    </div>
                  </li>

                  <li>
                    <div className="title">
                      <h3 className="h5"><i className="fa fa-map-marker"></i>Address</h3>
                    </div>
                    <div className="content">
                      <p>1234 Elm Street, Apt 56</p>
                      <p>Los Angeles, CA 90001</p>
                      <p>United States</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-9 col-sm-8 ptop--30 pbottom--30">
              <div className="comment--form">
                <div className="comment-respond">
                  <form onSubmit={handleSubmit}>
                    <div className="status"></div>

                    <div className="row">
                      <div className="col-xs-6 col-xxs-12">
                        <label>
                          <span>Name *</span>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                          />
                        </label>

                        <label>
                          <span>Email Address *</span>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            value={formData.email}
                            onChange={handleInputChange}
                            required
                          />
                        </label>

                        <label>
                          <span>Object</span>
                          <input
                            type="text"
                            name="website"
                            className="form-control"
                            value={formData.website}
                            onChange={handleInputChange}
                          />
                        </label>
                      </div>

                      <div className="col-xs-6 col-xxs-12">
                        <label>
                          <span>Message *</span>
                          <textarea
                            name="message"
                            className="form-control"
                            value={formData.message}
                            onChange={handleInputChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="col-md-12 text-right">
                        <button type="submit" className="btn btn-primary">Send Message</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
